import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { SessionService } from './session.service';
import { CreateTicketPayload  } from './models/required-actions/payloads/create-ticket-payload';
import { CompleteTicketPayload  } from './models/required-actions/payloads/complete-ticket-payload';
import { CompleteTaskPayload  } from './models/required-actions/payloads/complete-task-payload';
import { filter, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class RequiredActionService {
    private baseUrl: string;
    private requiredActionStream: BehaviorSubject<any[]> = new BehaviorSubject(null);

    constructor(private http: HttpClient, private sessionService: SessionService) {
        this.baseUrl = environment.baseUrl;
    }

    get requiredActions(): Observable<any[]> {
        return this.requiredActionStream
            .pipe(
                filter(x => !!x)
            );
    }

    public createTicket(createTicketPayload: CreateTicketPayload): Observable<any> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.post(`${this.baseUrl}account/${x.id}/createticket`, createTicketPayload);
                })
            );
    }

    public completeTicket(completeTicketPayload: CompleteTicketPayload): Observable<any> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.post(`${this.baseUrl}account/${x.id}/completeticket`, completeTicketPayload);
                })
            );
    }

    public completeTask(completeTaskPayload: CompleteTaskPayload): Observable<any> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.post(`${this.baseUrl}account/${x.id}/completetask`, completeTaskPayload);
                })
            );
    }

    public loadRequiredActions(): void {
        this.getRequiredActions().subscribe(x => {
            this.requiredActionStream.next(x);
        });
    }

    private getRequiredActions(): Observable<any[]> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return !!x ? this.http.get<any[]>(`${this.baseUrl}account/${x.id}/requiredactions`) :
                    of([]);
                })
            );
    }
}
