<div>
  <div class="alert alert-success" *ngIf="state === 'in'" [@fadeInOut]="state">
    {{ 'website_access_saved' | translate }}
  </div>
  <div *ngIf="credentialsForm">
    <form [formGroup]="credentialsForm" (submit)="save($event)">
      <div class="row">
        <div class="col-lg-6" *ngFor="let customerAccount of customerAccounts;">
          <app-website-credentials class="ms-4" [formGroupName]="getFormName(customerAccount)"
            [credential]="customerAccount" [credentialForm]="getForm(customerAccount)">
          </app-website-credentials>
          <div *ngIf="showFormErrors && getForm(customerAccount).invalid" class="alert alert-danger">
            {{ getErrorMessage(getForm(customerAccount)) }}
          </div>
        </div>
      </div>
      <div class="mt-4 float-end">
        <button class="btn btn-lga btn-success btn-block" type="submit" [disabled]="!credentialsForm.dirty">{{ 'shared.button_save' | translate }}</button>
      </div>
    </form>
  </div>
</div>
