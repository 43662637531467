<div class="list-group-item requiredAction" *hideKey="'Seo-Dashboard-RequiredAction-GooglePin'">
  <div class="d-flex flex-wrap mb-2">
    <div class="mb-1 text-danger custom-required-actions-item-title" style="min-width: 100%">
      <i class="fa fa-exclamation-circle fa-2x text-danger float-start pe-2"></i>{{ 'ra_google_pin' | translate }}
    </div>
    <div class="text-muted ms-4 mb-2" style='min-width: 38%'>
      <small>
        <span *ngIf="isDescriptionCollapsed" (click)="isDescriptionCollapsed = !isDescriptionCollapsed" class="clickable customer-required-actions-text font-italic">
          <i class="fa fa-plus-circle customer-required-actions-text"></i> {{ 'view_details' | translate }}
        </span>
        <span *ngIf="!isDescriptionCollapsed" (click)="isDescriptionCollapsed = !isDescriptionCollapsed" class="clickable customer-required-actions-text font-italic">
          <i class="fa fa-minus-circle customer-required-actions-text"></i> {{ 'ra_google_pin_desc' | translate: { date: requiredAction.date | date:'shortDate' } }}
        </span>
      </small>
    </div>
    <div class="ms-auto" style="min-width: 38%" *hideKey="'Seo-Dashboard-RequiredAction-GooglePin-Button'">
      <button (click)='redirect()' target="_blank" class="btn btn-primary btn-sm align-self-start custom-required-actions-item-button btn-block">
        {{'contact_us' | translate }}
      </button>
    </div>
  </div>
</div>
