<div class="card" id="login-card">
  <div class="card-body">
    <div class="container d-flex p-4 flex-column">
      <form class="form-signin p-4" [formGroup]="loginForm" (submit)="login($event)" novalidate>
        <h2 class="card-title text-primary custom-tile-header pb-2">
          {{ 'page_title_general_login' | translate }}</h2>
        <div class="alert alert-danger" role="alert" *ngIf="didFail">
          {{ 'error_login_invalid' | translate }}
        </div>
        <div class="alert alert-info p-3" role="alert" *ngIf="newUser">
          <h5>{{ 'help_newuser_welcome' | translate }}</h5>
          <span>{{ 'help_password_not_created_yet' | translate }}</span><br>
          <span>{{ 'help_login_issues' | translate }}</span>
        </div>
        <div class="mb-3">
          <label for="inputEmail" class="sr-only form-label">{{ 'content_login_username' | translate }}</label>
          <input type="email" id="inputEmail" class="form-control" placeholder="{{ 'email' | translate }}" required=""
            autofocus="" autocomplete="off" formControlName="email" [ngClass]="{'is-invalid' : loginForm.controls['email'].dirty && loginForm.controls['email'].invalid }">
          <div class="invalid-feedback">
            {{ 'enter_valid_email' | translate }}
          </div>
        </div>
        <div>
          <label class="sr-only form-label">{{ 'content_login_password' | translate }}</label>
          <input type="password" id="inputPassword" class="form-control" placeholder="{{ 'content_login_password' | translate }}" required=""
            autofocus="" autocomplete="off" formControlName="password" [ngClass]="{'is-invalid' : loginForm.controls['password'].dirty && loginForm.controls['password'].invalid }">
          <div class="invalid-feedback">
            {{ 'error_login_username_password_required' | translate }}
          </div>
        </div>

        <div class="d-grid gap-2 mt-3">
          <button class="btn btn-lg btn-primary" type="submit"><span class="fa fa-sign-in-alt"></span> {{ 'button_log_in' | translate }}</button>
          <button *ngIf="siteConfig && !(siteConfig?.partnerIsContactRestricted)" class="btn btn-lg btn-outline-secondary btn-block"
            routerLink="/forgot-password">{{ 'button_forgot_password' | translate }}</button>
        </div>

      </form>
      <div class="p-4">
        <app-language-selector class="d-inline d-md-none"></app-language-selector>
      </div>
    </div>
  </div>
</div>
