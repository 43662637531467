<div class="d-flex justify-content-center m-4">
  <div class="card text-white bg-danger mb-3" style="max-width: 30rem;">
    <div class="card-body">
      <!-- TODO: Translate --> 
      <h4 class="card-title">{{ 'error_occurred' | translate }}</h4>
      <p *ngIf="!!this.errorMessage"class="card-text">
       {{ this.errorMessage | translate }} {{'error_general_instructions' | translate }}
      </p>
      <p *ngIf="!this.errorMessage">
        {{ 'error_general' | translate }}
      </p>

      <button class="btn btn-block btn-warning text-light" (click)="home()"><span class="fa fa-home"></span> {{ 'button_go_home' | translate }}</button>
      <button class="btn btn-block btn-warning text-light" (click)="back()"><span class="fa fa-arrow-left"></span> {{ 'button_back' | translate }}</button>
    </div>
  </div>