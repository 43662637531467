import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { SessionService } from './session.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class FeatureRequired  {

    constructor(private router: Router, private sessionService: SessionService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let result = false;

        const requiredFeature = route.data['requiredFeature'];

        return this.sessionService.account
            .pipe(
                tap(account => {
                    if (account.hasFeature(requiredFeature)) {
                        result = true;
                    } else {
                        this.router.navigateByUrl('log-in');
                    }
                }),
                map(x => result)
            );
    }
}

@Injectable()
export class UserContactPermitted  {

    constructor(private router: Router, private sessionService: SessionService) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.sessionService.siteConfig
            .pipe(
                tap(config => {
                    if (!config || config.partnerIsContactRestricted) {
                        this.router.navigateByUrl('log-in');
                        return false;
                    }
                    return true;
                }),
                map(x => x && !x.partnerIsContactRestricted)
            );
    }
}
