<div class="card text-center">
  <div class="card-body d-flex flex-column">
    <h4 class="card-title text-primary text-center custom-tile-header">
      {{ 'local_listings.title_google_customer_actions' | translate}}
      <small>
        <helpPopover placement='top' html="{{ 'local_listings.help_google_customer_actions' | translate }}"></helpPopover>
      </small>
    </h4>
    <h6 class="text-secondary">({{ 'local_listings.stats_last_x_days' | translate: { days: '90'} }})</h6>
    <div *ngIf="chartData" class="row">
      <app-google-my-business-status [googleMyBusinessStatus]="googleMyBusinessStatus" [hasChartData]="chartData.hasData" [locationId]="locationId"></app-google-my-business-status>
      <div class="col-12">
        <div class="p-2 text-center text-md-left d-flex flex-column h-100">
          <canvas class="chart-canvas" baseChart
                  [data]="data"
                  [labels]="labels"
                  [options]="options"
                  [colors]="chartColors"
                  [type]="'line'"></canvas>
        </div>
      </div>
      <div class="col-6">
        <div><i class="fas fa-desktop"></i> {{ websiteConversionRate | percent: '1.2-2' }}</div>
        <div>{{ 'local_listings.stats_google_website_click_conversion_rate' | translate }}</div>
      </div>
      <div class="col-6">
        <div><i class="fas fa-sync-alt"></i> {{ overallConversionRate | percent: '1.2-2' }}</div>
        <div>{{ 'local_listings.stats_google_overall_conversion_rate' | translate }}</div>
      </div>
    </div>
  </div>
</div>
