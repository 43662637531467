<div class="card col-md-5">
  <div class="card-body">
    <h4 class="card-title text-primary">{{'content_change_password' | translate}}</h4>
    <form class="form-signin" [formGroup]="changePasswordForm" novalidate (submit)="change($event)">
      <div class="alert alert-success" *ngIf="state === 'in'" [@fadeInOut]="state">Your password has been changed.</div>
      <div class="alert alert-danger" role="alert" *ngIf="didFail">
        {{ 'error_email_password_invalid' | translate }}
      </div>
      <div class="form-group">
        <label for="password" class="sr-only">{{'content_login_password' | translate}}</label>
        <input type="password" id="currentPassword" class="form-control"
          placeholder="{{'content_login_password' | translate }}" required="" autofocus=""
          autocomplete="off" formControlName="currentPassword"
          [ngClass]="{'is-invalid' : changePasswordForm.controls['currentPassword'].dirty && changePasswordForm.controls['currentPassword'].invalid }">
        <div class="invalid-feedback" *ngIf="changePasswordForm.controls.confirmPassword.errors?.Match">
          {{ 'error_password_matching' | translate }}
        </div>
      </div>
      <div class="form-group">
        <label for="inputPassword" class="sr-only">{{'content_change_password_new_password' | translate}}</label>
        <input type="password" id="inputPassword" class="form-control" placeholder="{{'content_change_password_new_password' | translate}}"
          required="" autofocus="" autocomplete="off" formControlName="newPassword" [ngClass]="{'is-invalid' : changePasswordForm.controls['newPassword'].dirty && changePasswordForm.controls['newPassword'].invalid }">
        <div class="invalid-feedback" *ngIf="changePasswordForm.controls.newPassword.errors?.pattern">
          {{ 'error_password_pattern' | translate }}
        </div>
      </div>
      <div class="form-group">
        <label for="inputConfirmPassword" class="sr-only">{{'content_change_password_confirm_new_password' | translate }}</label>
        <input type="password" id="inputConfirmPassword" class="form-control" placeholder="{{'content_change_password_confirm_new_password' | translate }}"
          required="" autofocus="" autocomplete="off" formControlName="confirmPassword" [ngClass]="{'is-invalid' : changePasswordForm.controls['confirmPassword'].dirty && changePasswordForm.controls['confirmPassword'].invalid }">
        <div class="invalid-feedback" *ngIf="changePasswordForm.controls.confirmPassword.errors?.Match">
          {{ 'error_password_matching' | translate }}
        </div>
      </div>
      <button class="btn btn-md btn-primary btn-block" type="submit">{{'content_change_password' | translate}}</button>
    </form>
  </div>
</div>
