<div class="card">
  <div class="card-body">
    @if (localProfiles.length) {
      <div class="row mb-4">
        <div class="col-4">
          @if (localProfiles.length === 1) { 
            <h4>{{ localProfiles[0].title }}</h4>
          } @else {
            <select class="form-select d-inline" [(ngModel)]="selectedLocalProfileId">
              <option *ngFor="let localProfile of localProfiles" [ngValue]="localProfile.localProfileId">{{ localProfile.title }}</option>
            </select>
          }
        </div>
      </div>
      <div ngbAccordion accordion="NgbAccordion">
        <ng-container *ngFor="let history of selectedHistory(); index as i">
            <div [ngbAccordionItem]="'history_' + i" [destroyOnHide]="false">
              <h2 ngbAccordionHeader>
                <button ngbAccordionButton>{{ history.title }}<span class="ms-4"><i class="fas fa-sync-alt"></i> {{ 'listing_history.sync_date' | translate }}: {{ history.syncDate | localDate }}</span></button>
              </h2>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <ng-container *ngFor="let property of history.locationSnapshot | keyvalue : sortAsProvided">
                      <ng-container [ngSwitch]="true">
    
                        <!-- business hours -->
                        <div *ngSwitchCase="property.key === 'business_hours'">
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>:
                          <ng-container *ngFor="let businessHoursProperty of property.value | keyvalue : sortAsProvided">
                            <ng-container [ngSwitch]="businessHoursProperty.key">
                              <span *ngSwitchCase="'information'">
                                {{ 'listing_history.' + businessHoursProperty.value | translate }}
                              </span>
                              <span *ngSwitchCase="'additional_information'">
                                {{ businessHoursProperty.value }}
                              </span>
                              <span *ngSwitchCase="'hours'">
                                <ul class="mb-0">
                                  <li *ngFor="let hoursProperty of businessHoursProperty.value">
                                    <ng-container *ngFor="let hourProperty of hoursProperty | keyvalue : sortAsProvided">
                                      <ng-container [ngSwitch]="'hour_' + hourProperty.key">
                                        <span *ngSwitchCase="'hour_name'"><strong>{{ 'listing_history.' + hourProperty.value | translate}}</strong>: </span>
                                        <span *ngSwitchCase="'hour_information'">{{ 'listing_history.' + hourProperty.value | translate}}</span>
                                        <span *ngSwitchCase="'hour_hours'">{{ hourProperty.value }}</span>
                                      </ng-container>
                                    </ng-container>
                                  </li>
                                </ul>
                              </span>
                            </ng-container>
                          </ng-container>
                        </div>
    
                        <!-- special hours -->
                        <div *ngSwitchCase="property.key === 'special_business_hours'">
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>:
                          <ul class="mb-0">
                            <li *ngFor="let specialHours of property.value">
                              <ng-container *ngFor="let specialHour of specialHours | keyvalue : sortAsProvided">
                                <ng-container [ngSwitch]="'hour_' + specialHour.key">
                                  <span *ngSwitchCase="'hour_date'"><strong>{{ specialHour.value | localDate }}</strong>: </span>
                                  <span *ngSwitchCase="'hour_information'">{{ 'listing_history.' + specialHour.value | translate }}</span>
                                  <span *ngSwitchCase="'hour_hours'">{{ specialHour.value }}</span>
                                  <span *ngSwitchCase="'hour_description'"> ({{ specialHour.value }})</span>
                                </ng-container>
                              </ng-container>
                            </li>
                          </ul>
                        </div>
    
                        <!-- date type -->
                        <div *ngSwitchCase="['opening_date'].includes(property.key)">
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>: {{ property.value | localDate }}
                        </div>
    
                        <!-- boolean type -->
                        <div *ngSwitchCase="['is_closed', 'is_street_address_hidden'].includes(property.key)">
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>: {{ 'listing_history.' + property.value | translate }}
                        </div>
    
                        <!-- array type -->
                        <div *ngSwitchCase="['categories', 'service_areas', 'social_profiles'].includes(property.key)">
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>:
                          <ul class="mb-0">
                            <li *ngFor="let item of property.value">{{ item }}</li>
                          </ul>
                        </div>
    
                        <!-- array type with value translation -->
                        <div *ngSwitchCase="['payment_options'].includes(property.key)">
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>:
                          <ul class="mb-0">
                            <li *ngFor="let item of property.value">{{ 'listing_history.' + item | translate }}</li>
                          </ul>
                        </div>
    
                        <!-- default -->
                        <div *ngSwitchDefault>
                          <strong>{{ 'listing_history.' + property.key | translate }}</strong>: {{ property.value }}
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-container>
      </div>
    } @else {
      <span class="float-start">
        <h4 class="text-primary">{{ 'local_listings.title_not_ready' | translate }}</h4>
      </span>
    }
  </div>
</div>