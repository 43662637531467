<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-6">
        
        <ng-container *ngIf="locationBusinessCitations.length > 1">
          <span class="float-start">
            <h4 class="text-primary">{{ 'local_listings.title_business_citation_location' | translate}}</h4>
          </span>
          <span class="float-start">
            <select class="d-inline" class="form-select ms-2 mt-1 pt-1 pb-1" [(ngModel)]="selectedLocationBusinessCitation">
              <option *ngFor="let locationBusinessCitation of locationBusinessCitations" [ngValue]="locationBusinessCitation">{{ locationBusinessCitation.location.displayName }}</option>
            </select>
          </span>
        </ng-container>
        <ng-container *ngIf="locationBusinessCitations.length == 1">
          <span class="float-start">
            <h4 class="text-primary">{{ 'local_listings.title_business_citation_location' | translate }}: {{ selectedLocationBusinessCitation.location.displayName }}</h4>
          </span>
        </ng-container>
        <ng-container *ngIf="locationBusinessCitations.length == 0">
          <span class="float-start">
            <h4 class="text-primary">{{ 'local_listings.title_not_ready' | translate }}</h4>
          </span>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="selectedLocationBusinessCitation">
      <!-- Phone -->
      <div class="mt-4 d-block d-md-none">
        <div class="row">
          <div class="col-12">
            <app-location-card *hideKey="'BusinessCitations-LocationCard'" [location]="selectedLocationBusinessCitation.location" [states]="states"></app-location-card>
          </div>
          <div class="col-12" *ngIf="selectedLocationBusinessCitation.location.latitude && selectedLocationBusinessCitation.location.longitude" style="min-height: 500px;">
              <app-location-map [allowPan]="false" *hideKey="'BusinessCitations-LocationMap'" [location]="selectedLocationBusinessCitation.location"></app-location-map>
          </div>
        </div>
      </div>
      <!-- Desktop -->
      <div class="mt-4 d-none d-md-block">
        <div class="row">
          <div class="col-6">
            <app-location-card [location]="selectedLocationBusinessCitation.location" [states]="states"></app-location-card>
          </div>
          <div class="col-6" *ngIf="selectedLocationBusinessCitation.location.latitude && selectedLocationBusinessCitation.location.longitude">
            <app-location-map [allowPan]="true" *hideKey="'BusinessCitations-LocationMap'" [location]="selectedLocationBusinessCitation.location"></app-location-map>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-container *hideKey="'BusinessCitations-LocationBusinessCitations'" >
  <ng-container *ngIf="selectedLocationBusinessCitation">
    <app-location-business-citations [locationBusinessCitation]="selectedLocationBusinessCitation" ></app-location-business-citations>
  </ng-container>
</ng-container>

<div class="d-flex flex-wrap justify-content-between" *ngIf="selectedLocationBusinessCitation">
  <app-google-views-by-date class="p-2 tile" *hideKey="'BusinessCitations-Google-Views'" [googleMyBusinessStatus]="selectedLocationBusinessCitation.googleMyBusinessStatus" [locationId]="selectedLocationBusinessCitation.location.id" [googleViewsByDate]="selectedLocationBusinessCitation.googleViewsByDate"></app-google-views-by-date>
  <app-google-actions-by-date class="p-2 tile" *hideKey="'BusinessCitations-Google-Customer-Actions'" [googleMyBusinessStatus]="selectedLocationBusinessCitation.googleMyBusinessStatus" [locationId]="selectedLocationBusinessCitation.location.id" [googleActionsByDate]="selectedLocationBusinessCitation.googleActionsByDate"></app-google-actions-by-date>
</div>

<div class="row mt-2" *ngIf="account; let a">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <p class="mb-0 text-muted text-center custom-tile-subheader" *hideKey="'Seo-CustomerUrl'">{{ a.url }}</p>
      </div>
    </div>
  </div>
</div>
