import { LocationService } from '../location.service';
import { LocationBusinessCitation } from '../models/location-business-citation';
import { BaseComponent } from '../../base.component';
import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { SessionService } from '../../shared/index';
import { StateService } from '../../shared/state.service';
import { State, Account } from '../../shared/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-business-citations',
  templateUrl: './business-citations.component.html',
  styleUrls: ['./business-citations.component.scss'],
  providers: [TranslatePipe]
})
export class BusinessCitationsComponent extends BaseComponent implements OnInit {

  public selectedLocationBusinessCitation: LocationBusinessCitation;
  public locationBusinessCitations: LocationBusinessCitation[] = [];
  public states: State[];
  public account: Account;

  constructor(private locationService: LocationService,
    private stateService: StateService,
    public sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.locationService
      .getLocationBusinessCitations()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(locationBusinessCitations => {
        this.locationBusinessCitations = locationBusinessCitations;
        if (this.locationBusinessCitations.length > 0) {
          this.selectedLocationBusinessCitation = this.locationBusinessCitations[0];
        }
      });

    this.stateService
      .getStates()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(states => {
        this.states = states;
      });

    this.sessionService.account
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(account => {
        this.account = account;
      });
  }
}
