<div *ngIf="isVisible" class="offset-2 col-8 text-center overlap-chart">
  <div *ngIf="googleConnectionProcessing">
    <ng-template *ngTemplateOutlet="connectGoogle"></ng-template>
    <h4>{{ 'local_listings.google_business_profile_processing' | translate}}</h4>
    <h4><a class="fa fa-redo text-success clickable" role="button" (click)="refresh()"></a></h4>
    <h5>{{ 'local_listings.google_business_profile_processing_contact' | translate }}</h5>
  </div>
  <div *ngIf="noGoogleConnection">
    <ng-template *ngTemplateOutlet="connectGoogle"></ng-template>
    <h4>{{ 'local_listings.google_business_profile_connect_for_insights' | translate }}</h4>
    <h4><a class="fa fa-redo text-success clickable" role="button" (click)="refresh()"></a></h4>
  </div>
  <div *ngIf="noDataProvided">
    <h4>{{ 'local_listings.no_chart_data_provided' | translate }}</h4>
    <h5>{{ 'local_listings.google_business_profile_processing_contact' | translate }}</h5>
  </div>
</div>

<ng-template #connectGoogle>
  <button class="float-end" (click)="connectGoogleBusiness()" type="button" class="btn p-2 btn-primary btn-lg mb-2">
    <span class="ms-2 me-2">
      <img [src]="'/assets/google-g.png'" class="me-2 googleIcon"/>{{ 'content_connect_account' | translate }}
    </span>
  </button>
</ng-template>