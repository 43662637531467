import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ChartData } from '../../../shared/models/chartData';
import { BaseComponent } from '../../../base.component';
import { Component, Input, OnInit } from '@angular/core';
import { ChartColorService } from '../../../shared/chart-colors.service';
import { takeUntil } from 'rxjs/operators';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { GoogleViewsByDate } from '../../models/google-views-by-date';
import { ChartLabelService } from '../../../shared/chart-label.service';

@Component({
  selector: 'app-google-views-by-date',
  templateUrl: './google-views-by-date.component.html',
  styleUrls: ['./google-views-by-date.component.scss']
})
export class GoogleViewsByDateComponent extends BaseComponent implements OnInit {

  @Input() googleMyBusinessStatus: number;
  @Input() locationId: number;
  @Input()
  set googleViewsByDate(googleViewsByDate: GoogleViewsByDate) {
    this._chartData = googleViewsByDate.googleViewsMetrics;

    if (!this._chartData.hasData) {
      this._chartData = this.disconnectedDataSet;
      this.options.scales.y.max = 100;
    }

    this.totalViews = googleViewsByDate.totalViews;
    this.directionRequestConversionRate = googleViewsByDate.directionRequestConversionRate;
    this.translateLabels(this.chartData);
    this.labels = this.chartData.labels;
    this.datasets = this.chartData.dataSet;

    this.data.labels = this.labels;
    this.datasets.forEach((dataset, index) => {
      this.data.datasets[index].data = dataset.data;
      this.data.datasets[index].label = dataset.label;
    });
  }

  totalViews: number;
  directionRequestConversionRate: number;
  labels;
  datasets: any[];
  chartColors: Array<any>;
  data: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: [
      {
        data: [],
        fill: true,
        tension: 0.4,
      },
      {
        data: [],
        fill: true,
        tension: 0.4,
      }
    ]
  };
  options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        callbacks: {
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.parsed.y}`;
          }
        }
      }
    },
    scales: {
      y: {
        stacked: true,
        min: 0,
        ticks: {
          callback: function (value, i, t) {
            return Number.isInteger(value) ? value : '';
          }
        }
      },
      x: {
        stacked: true,
      }
    }
  };

  private _chartData: ChartData;
  private disconnectedDataSet = {
    "hasData": false,
    "labelKeys": this.chartLabelService.getPreviousMondays(13),
    "labels": null,
    "dataSet": [
      {
        "label": null,
        "labelKey": "local_listings.label_views_on_map",
        "data": []
      },
      {
        "label": null,
        "labelKey": "local_listings.label_views_on_search",
        "data": []
      }
    ]
  };

  constructor(
    private translate: TranslateService,
    private chartColorService: ChartColorService,
    private chartLabelService: ChartLabelService) {
    super();
  }

  ngOnInit() {
    this.chartColorService.filledLine
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((chartColors) => {
        this.chartColors = chartColors;
        this.data.datasets.forEach((x, index) => {
          x.backgroundColor = this.chartColors[index].backgroundColor;
          x.borderColor = this.chartColors[index].borderColor;
          x.borderWidth = this.chartColors[index].borderWidth;
        });
      });
  }

  get chartData(): ChartData {
    return this._chartData;
  }

  private translateLabels(chartData: ChartData) {
    chartData.labels = [];
    for (const i of chartData.labelKeys) {
      chartData.labels.push(moment(i).format('L'));
    }

    for (const i of chartData.dataSet) {
      i.label = this.translate.instant(i.labelKey);
    }
  }
}
