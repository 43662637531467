import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocationBusinessCitation } from './models/location-business-citation';
import { LocationSyncHistory } from './models/location-sync-history';
import { Location } from './models/location';
import { SessionService } from '../shared/session.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LocationService {
    private baseUrl: string;
    constructor(private http: HttpClient, private sessionService: SessionService, private translate: TranslateService) {
        this.baseUrl = environment.baseUrl;
    }

    public getLocationBusinessCitations(): Observable<LocationBusinessCitation[]> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.get<LocationBusinessCitation[]>(`${this.baseUrl}account/${x.id}/location-business-citations`);
                })
            );
    }

    
    public getLocationSyncHistory(): Observable<LocationSyncHistory[]> {
      return this.sessionService.account
          .pipe(
              mergeMap(x => {
                  return this.http.get<LocationSyncHistory[]>(`${this.baseUrl}account/${x.id}/location-sync-history`);
              })
          );
    }

    public saveLocation(location: Location): Observable<any> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.post(`${this.baseUrl}account/${x.id}/location-save`, location);
                })
            );

    }

    public connectGoogleMyBusiness(locationId: number): Observable<string> {
        return this.sessionService.account
            .pipe(
                mergeMap(x => {
                    return this.http.get<string>(`${this.baseUrl}account/${x.id}/${locationId}/${this.translate.currentLang}/connect-google-my-business`);
                })
            );
    }
}
