import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {

  constructor() { }

  private errorConfig = {
    'data': [
      /\/keyword/,
      /\/guarantee$/,
      /\/account\/\d*\/work/,
      /\/google\/analytics\//,
      /\/SeoContentSurvey\/\d*\/get$/,
      /\/location-business-citations$/,
      /\/protips$/,
      /\/requiredactions$/
  ],
    'google_connect': [
      /\/account\/\d*\/google/
    ],
    'action_submission': [
      /\/createticket$/,
      /\/completeticket$/,
      /\/completetask$/
    ]
  };

  public getDisplayErrorKey(url: string): string {
    if (this.errorConfig.data.some((regex: RegExp) => regex.test(url))) {
      return 'error_load_data';
    }

    if (this.errorConfig.google_connect.some((regex: RegExp) => regex.test(url))) {
      return 'error_google_connect';
    }

    if (this.errorConfig.action_submission.some((regex: RegExp) => regex.test(url))) {
      return 'error_action_submission';
    }

    return null;
  }
}



