

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { of } from 'rxjs';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { map, mergeMap, share, skipWhile, takeWhile } from 'rxjs/operators';

import { Account, SiteConfig, Token, User, ExternalToolbarConfig } from './models';
import { StorageService } from './storage.service';

@Injectable()
export class SessionService {
    private _account: BehaviorSubject<Account> = new BehaviorSubject<Account>(null);
    private _isCsr: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _siteConfig: BehaviorSubject<SiteConfig> = new BehaviorSubject<SiteConfig>(null);
    private _externalToolbarConfig: BehaviorSubject<ExternalToolbarConfig> = new BehaviorSubject<ExternalToolbarConfig>(null);
    private _token: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);
    private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private _canSwitchAccounts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _locale = '';
    private _lock: 'site' | 'account';
    private _redirectUrl: string;
    public isLoggingOut = false;

    constructor(private storage: StorageService, private titleService: Title) {
        this.setSiteConfig(storage.get<SiteConfig>('siteConfig'), 'account');
        this.setToken(storage.get<Token>('token'));
        this.setUser(storage.get<User>('user'));
        this.setAccount(storage.get<Account>('account'));
        this.setIsCsr(storage.get<boolean>('isCsr'));
        this.setCanSwitchAccounts(this.storage.get<boolean>('canSwitchAccounts'));
        this.setExternalToolBarConfig(this.storage.get<ExternalToolbarConfig>('externalToolbarConfig'));
        const storedLocale = this.storage.get<string>('locale');
        this.locale = storedLocale ? storedLocale : this.getBrowserLocale();
    }

    get token(): Observable<Token> {
        return this._token.asObservable()
            .pipe(
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    public setToken(aToken: Token) {
        this.storage.set('token', aToken);
        this._token.next(aToken);
    }

    get account(): Observable<Account> {
        return this._account.asObservable()
            .pipe(
                share(),
                skipWhile(x => !x),
                skipWhile(x => this.isLoggingOut)
            );
    }

    get isCsr(): Observable<boolean> {
      return this._isCsr.asObservable()
        .pipe(
            share(),
            takeWhile(x => !this.isLoggingOut)
        );
    }

    public setAccount(account: Account) {
        this.storage.set('account', account);

        if (!account) {
            this._account.next(account);
            return;
        }

        account.hasFeature = (feature: string) => account.features ? account.features.indexOf(feature) > -1 : false;

        this._account.next(account);
        this.setSiteConfig(account.siteConfig, 'account');
    }

    public setIsCsr(isCsr: boolean) {
      this.storage.set('isCsr', isCsr);
      this._isCsr.next(isCsr);
    }

    get siteConfig(): Observable<SiteConfig> {
        return this._siteConfig.asObservable()
            .pipe(
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    public setSiteConfig(siteConfig: SiteConfig, lock: 'site' | 'account') {
        if (siteConfig && this._lock === 'account' && lock === 'site') {
            return;
        }
        this.storage.set('siteConfig', siteConfig);

        this._siteConfig.next(siteConfig);

        if (siteConfig) {
            this._lock = lock;
            this.style(siteConfig.css);
        }

        // If the title is not hidden through the hidekeys then set it to partnerName
        const pageTitleHideKey = 'PAGE-TITLE';
        if (siteConfig && !siteConfig.hiddenContent.some(x => x.toUpperCase() === pageTitleHideKey)) {
            this.titleService.setTitle(siteConfig.partnerName);
        }
    }

    public setExternalToolBarConfig(externalToolbarConfig: ExternalToolbarConfig) {
        this.storage.set('externalToolbarConfig', externalToolbarConfig);
        this._externalToolbarConfig.next(externalToolbarConfig);
    }

    get externalToolbarConfig(): Observable<ExternalToolbarConfig> {
        return this._externalToolbarConfig.asObservable()
            .pipe(
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    get user(): Observable<any> {
        return this._user.asObservable()
            .pipe(
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    public setUser(user: any) {
        this.storage.set('user', user);
        this._user.next(user);
    }

    public isAuthorized(): Observable<boolean> {
        return this._token
            .pipe(
                map(x => !!x),
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    public isNotAuthorized(): Observable<boolean> {
        return this._token
            .pipe(
                map(x => !x),
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    public isReady(): Observable<boolean> {
        return this.isAuthorized()
            .pipe(
                mergeMap(x => {
                    if (!x) {
                        return of(false);
                    }

                    return this._account.pipe(
                        map(y => !!y)
                    );
                }),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    get canSwitchAccounts(): Observable<boolean> {
        return this._canSwitchAccounts.asObservable()
            .pipe(
                share(),
                takeWhile(x => !this.isLoggingOut)
            );
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this.storage.set('locale', value);
        this._locale = value;
    }

    get redirectUrl(): string {
        return this._redirectUrl;
    }

    set redirectUrl(value: string) {
        this._redirectUrl = value;
    }

    public clearRedirectUrl() {
        this.redirectUrl = null;
    }

    public setCanSwitchAccounts(canSwitch: boolean) {
        this.storage.set('canSwitchAccounts', canSwitch);
        this._canSwitchAccounts.next(canSwitch);
    }

    public clear(): void {
        this._lock = null;
        this.storage.clear();
        this._token.next(null);
        this._user.next(null);
        this._account.next({} as Account);
        this._isCsr.next(null);
        this._siteConfig.next(null);
        this._externalToolbarConfig.next(null);
        this._canSwitchAccounts.next(false);
        this._locale = this.getBrowserLocale();
    }

    private style(css: string) {
        const elementId = 'custom-css';
        const oldStyleElement = document.getElementById(elementId);
        if (oldStyleElement) {
            document.body.removeChild(oldStyleElement);
        }
        const newStyleElement = document.createElement('style');
        newStyleElement.id = elementId;
        newStyleElement.innerHTML = css;
        document.body.appendChild(newStyleElement);
    }

    private getBrowserLocale(): string {
        return navigator.language;
    }
}
