import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../base.component';
import { AccountService } from '../../../../structure/account.service';
import { OnsiteRecommendationSettings } from '../../../../shared/models/onsite-recommendation-settings';
import { RequiredActionService } from '../../../../shared/required-action.service';
import { OnsiteRecommendationApproval } from '../../../../shared/models/required-actions/onsite-recommendation-approval';
import { takeUntil } from 'rxjs/operators';
import { SessionService } from '../../../../shared';

enum Responsibilities {
  NotSelected = 0,
  Customer = 1,
  Boostability = 2,
  Partner = 3
}

@Component({
  selector: 'app-onsite-recommendation-approval',
  templateUrl: './onsite-recommendation-approval.component.html',
  styleUrls: ['./onsite-recommendation-approval.component.css']
})
export class OnsiteRecommendationApprovalComponent extends BaseComponent implements OnInit {
  @Input() requiredAction: OnsiteRecommendationApproval;

  public isDescriptionCollapsed: boolean;
  public isUrlCollapsed: boolean;
  public isDownloaded: boolean;
  public isRejection: boolean;
  public isActionComplete: boolean;
  public isActionRejected: boolean;
  public actionMessage: string;
  public isImplementedByCustomer: boolean;
  public onsiteRecommendationSettings: OnsiteRecommendationSettings;
  public form: UntypedFormGroup;
  public rejectionNote: string;
  public isCsr: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private requiredActionService: RequiredActionService,
    private sessionService: SessionService) {
    super();
  }

  ngOnInit() {
    this.isDescriptionCollapsed = true;
    this.isUrlCollapsed = true;
    this.isDownloaded = false;
    this.isRejection = false;
    this.isActionComplete = false;
    this.isImplementedByCustomer = false;

    this.sessionService.isCsr.subscribe(isCsr => this.isCsr = isCsr);

    this.form = this.formBuilder.group({
      note: ['', [Validators.required, Validators.maxLength(1000)]]
    });

    this.accountService.onsiteRecommendationSettings()
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((onsiteRecommendationSettings: OnsiteRecommendationSettings) => {
        this.onsiteRecommendationSettings = onsiteRecommendationSettings;
        this.isImplementedByCustomer = this.onsiteRecommendationSettings
          && this.onsiteRecommendationSettings.responsibility === Responsibilities.Customer;
      });
  }

  public download() {
    window.open(this.requiredAction.hyperlink);
    this.isDownloaded = true;
  }

  public approve() {
    return this.requiredActionService.completeTask(this.requiredAction.completeTaskPayload)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        this.isActionComplete = true;
      });
  }

  public reject() {
    if (this.form.valid) {
      if (this.requiredAction.displayUrl) {
        this.requiredAction.createTicketPayload.reason = `URL: ${this.requiredAction.displayUrl}\r\n\r\n${this.form.controls.note.value}`;
      } else {
        this.requiredAction.createTicketPayload.reason = this.form.controls.note.value;
      }

      return this.requiredActionService.createTicket(this.requiredAction.createTicketPayload)
        .pipe(
          takeUntil(this.unsubscribe)
        )
        .subscribe(() => {
          this.isActionRejected = true;
        });
    } else {
      this.form.controls.note.markAsDirty({ onlySelf: true });
    }
  }

  public startRejection() {
    this.isRejection = true;
  }

  public cancelRejection() {
    this.isRejection = false;
  }
}
