import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { BaseChartDirective } from 'ng2-charts';
import { FeatureRequired } from './account-guards';
import { FormattedNumberPipe } from './formatted-number.pipe';
import { HelpPopoverComponent } from './help-popover.directive';
import { HideKey } from './hide-key.directive';
import { KeywordRankService } from './keyword-rank.service';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LogService } from './log.service';
import { OrdinalPipe } from './ordinal.pipe';
import { ProTipService } from './pro-tip.service';
import { RequiredActionService } from './required-action.service';
import { SessionProhibited, SessionRequired } from './session-guards';
import { SessionService } from './session.service';
import { ChartColorService } from './chart-colors.service';
import { ChartLabelService } from './chart-label.service';
import { SortIcon } from './sort-icon.directive';
import { StorageService } from './storage.service';
import { TileComponent, TileContent } from './tile.directive';
import { UndefinedNumberPipe } from './undefined-number.pipe';
import { UserService } from './user.service';
import { ClickOutsideDirective } from './click-outside.directive';
import { StateService } from './state.service';
import { SeoReady } from '.';
import { ReloadService } from './reload.service';
import { ReloadRequired } from './reload-guard';
import { CustomerAccountService } from './customer-account.service';
import { SeoContentSurveyService } from './seo-content-survey.service';
import { LocalDatePipe } from './local-date.pipe';
import { ErrorService } from './errors/error.service';
import { AngularOpenlayersModule } from 'ng-openlayers';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        AngularOpenlayersModule,
        BaseChartDirective
    ],
    providers: [
        SessionService,
        SessionProhibited,
        SessionRequired,
        UserService,
        CustomerAccountService,
        SeoContentSurveyService,
        FeatureRequired,
        StorageService,
        KeywordRankService,
        ProTipService,
        RequiredActionService,
        LogService,
        ErrorService,
        ChartColorService,
        ChartLabelService,
        StateService,
        SeoReady,
        ReloadService,
        ReloadRequired
    ],
    declarations: [
        HelpPopoverComponent,
        FormattedNumberPipe,
        OrdinalPipe,
        TileComponent,
        TileContent,
        SortIcon,
        UndefinedNumberPipe,
        HideKey,
        LanguageSelectorComponent,
        ClickOutsideDirective,
        LocalDatePipe
    ],
    exports: [
        HelpPopoverComponent,
        OrdinalPipe,
        FormattedNumberPipe,
        TileComponent,
        TileContent,
        SortIcon,
        TranslateModule,
        UndefinedNumberPipe,
        HideKey,
        LanguageSelectorComponent,
        ClickOutsideDirective,
        LocalDatePipe
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                SessionService,
                SessionProhibited,
                SessionRequired,
                StorageService,
                ReloadService,
                ReloadRequired,
                TranslatePipe
            ]
        };
    }
}
