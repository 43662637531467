import { BaseComponent } from '../../../base.component';
import { Component, Input, OnInit } from '@angular/core';
import { LocationService } from '../../location.service';
import { takeUntil } from 'rxjs/operators';
import { BusinessCitationStatuses } from '../../models/enums/business-citation-statuses.enum';

@Component({
  selector: 'app-google-my-business-status',
  templateUrl: './google-my-business-status.component.html',
  styleUrls: ['./google-my-business-status.component.scss']
})
export class GoogleMyBusinessStatusComponent extends BaseComponent implements OnInit {

  @Input() googleMyBusinessStatus: number;
  @Input() hasChartData: boolean;
  @Input() locationId: number;

  googleConnectionProcessing: boolean = false;
  noGoogleConnection: boolean = false;
  noDataProvided: boolean = false;
  isVisible: boolean = false;

  constructor(private locationService: LocationService) {
    super();
  }

  ngOnInit() {
    this.googleConnectionProcessing = this.googleMyBusinessStatus === BusinessCitationStatuses.Updating && !this.hasChartData;
    this.noGoogleConnection = this.googleMyBusinessStatus === BusinessCitationStatuses.Unknown;
    this.noDataProvided = this.googleMyBusinessStatus === BusinessCitationStatuses.Synced && !this.hasChartData;

    this.isVisible = this.googleConnectionProcessing || this.noGoogleConnection || this.noDataProvided;
  }

  refresh() {
    window.location.reload();
  }
  
  connectGoogleBusiness(): void {
    this.locationService.connectGoogleMyBusiness(this.locationId)
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(x => {
        window.open(x, '_blank', 'popup=true,width=800,height=600');
      });
  }
}
